import { clientDataService } from "../config/axios";

export const loginEndpoint = async (data) =>{
  return await clientDataService({
    url: `/api/sign-in`,
    method: 'POST',
    data: data,
  });
}

export const loginGoogle = async (data) =>{
  return await clientDataService({
    url: `/api/sign-in-with-google`,
    method: 'POST',
    data: data,
  });
}

export const loginFacebook = async (data) =>{
  return await clientDataService({
    url: `/api/sign-in-with-facebook`,
    method: 'POST',
    data: data,
  });
}

