import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useGetEssaysList from "../essay/useGetEssaysList";
import useMe from "../me/useMe";
import AsideMenu from "./AsideMenu";
import Navigation from "./Navigation";
import { googleLogout } from '@react-oauth/google';

const DashboardLayout = ({ children }) => {
    const navigate = useNavigate();
    const essaysList = useGetEssaysList()?.data
    const meQuery = useMe()?.data;
    const me = meQuery?.data;
    const [navCollapsed, setNavCollapsed] = useState(true);
    const isDesctop = window.matchMedia("(max-width: 1199px)").matches;
    const location = useLocation()

    useEffect(() => {
      if (isDesctop) {
        setNavCollapsed(false)
      }
    },[location, isDesctop])

    const handleBurgerNav = () => {
      setNavCollapsed((prev) => !prev);
    };

    const handleLogOut = () => {
      localStorage.clear()
      navigate('/login')
      googleLogout()
    }

    return (
        <>
        {me && <Navigation handleBurgerNav={handleBurgerNav} me={me} handleLogOut={handleLogOut}/>}
        {navCollapsed && <AsideMenu handleLogOut={handleLogOut} essaysList={essaysList?.data}/> }
        <main id="main" className="main">
        <div className="">{children}</div>
        </main>
        </>
    );
  };
  
  export default DashboardLayout;