import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import useFormPersist from "react-hook-form-persist";
import EssayFormSectionsBlock from "./EssayFormSectionsBlock";
import EssayPlan from "./EssayPlan";
import WordCounter from "./WordCounter";

const EssayForm = ({ onSubmit, isDivided, setDivided, isTaskA, setTaskA }) => {
  const { t } = useTranslation("common");
  const [isClosed, setClosed] = useState(true);

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  useFormPersist("form", {
    watch,
    setValue,
    storage: localStorage,
  });

  useEffect(() => {
    setClosed(getValues("task_type") === "Task A");
    setTaskA(getValues("task_type") === "Task A");
  }, [getValues("task_type")]);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
    >
      <div className="row mb-3">
        <label htmlFor="theme" className="col-sm-2 col-form-label">
          {t("form.theme")}
        </label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            {...register("theme", { required: true })}
            placeholder={t("form.placeholder.theme")}
            style={{ height: "70px" }}
          ></textarea>
          <div className="d-flex justify-content-between">
            <div className="mt-2">
              {errors?.theme?.type === "required" && (
                <p className="error">{t("form.error")}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="time_taken" className="col-sm-2 col-form-label">
          {t("form.timeTaken")}
        </label>
        <div className="col-sm-10">
          <input
            type={"number"}
            min={0}
            max={65}
            className="form-control"
            defaultValue={20}
            {...register("time_taken", { required: true, max: 65 })}
            style={{ height: "40px" }}
          ></input>
          <div className="d-flex justify-content-between">
              <div className="mt-2">
                {errors?.time_taken?.type === "required" && (
                  <p className="error">{t("form.error")}</p>
                )}
              </div>
              <span className="small-description">{t("form.timeTakenMinutes")}</span>
            </div>
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="task_type" className="col-sm-2 col-form-label">
          {t("form.taskType")}
        </label>
        <div className="col-sm-10">
          <select
            className="form-select"
            id="validationDefault04"
            {...register("task_type", { required: true })}
            defaultValue={"Task A"}
          >
            <option>Task A</option>
            <option>Task B</option>
          </select>
          {errors?.task_type?.type === "required" && (
            <p className="error mt-2">{t("form.error")}</p>
          )}
          {!isClosed && (
            <div className=" col-md-5 mb-3 mt-3 chat left pt-2">
              <p>{t("form.message")}</p>
              <button
                type="button"
                className="btn btn-blue buttonMessage "
                onClick={() => {
                  setTaskA(true);
                  setClosed(true);
                }}
              >
                {t("form.yes")}
              </button>
              <button
                type="button"
                className="btn btn-blue buttonMessage "
                onClick={() => setClosed(true)}
              >
                {t("form.no")}
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="prompt" className="col-sm-2 col-form-label">
          {t("form.prompt")}
        </label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            {...register("prompt", { required: true })}
            placeholder={"Type the prompt of an essay"}
            style={{ height: "60px" }}
          ></textarea>
          <div className="d-flex justify-content-between">
            <div className="mt-2">
              {errors?.prompt?.type === "required" && (
                <p className="error">{t("form.error")}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {isTaskA && <EssayPlan register={register} errors={errors} watch={watch} />}
      <div className="form-check form-switch mb-3">
        <input
          className="form-check-input"
          checked={isDivided}
          type="checkbox"
          {...register("sectioned")}
          id="flexSwitchCheckDefault"
          onClick={() => setDivided((prev) => !prev)}
        />
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
          {t("form.divideEssay")}
        </label>
      </div>

      {isDivided ? (
        <EssayFormSectionsBlock watch={watch} register={register} errors={errors} />
      ) : (
        <div className="row mb-3">
          <label htmlFor="body" className="col-sm-2 col-form-label">
            {t("form.essay")}
          </label>
          <div className="col-sm-10">
            <textarea
              className="form-control"
              {...register("body", { required: true })}
              placeholder={t("form.placeholder.essay")}
              style={{ height: "300px" }}
            ></textarea>
            <div className="d-flex justify-content-between">
              <div className="mt-2">
                {errors?.body?.type === "required" && (
                  <p className="error">{t("form.error")}</p>
                )}
              </div>
              <WordCounter value={watch("body")} />
            </div>
          </div>
        </div>
      )}

      <div className="row mb-3">
        <label className="col-sm-2 col-form-label"></label>
        <div className="col-sm-10">
          <button type="submit" className="btn btn-blue credits">
            {t("button.evaluate")}
          </button>
          <p className="text-muted minus-credit">{t("form.minusCredit")}</p>
        </div>
      </div>
    </form>
  );
};
export default EssayForm;
