import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import EssayPage from "../essay/EssayPage";
import EssayPageResults from "../essay/EssayPageResults";
import Login from "../login/Login";
import SignUp from '../signup/SignUp';
import { PrivateRoute } from './PrivateRoute';
import Main from "../main/Main";
import Replenish from "../replenish/Replenish";
import SuccessPayment from "../replenish/SuccessPayment";
import HomePage from "../landing/HomePage/HomePage";
import Pricing from "../landing/Pricing/Pricing";
import Terms from "../landing/Terms/Terms";
import About from "../landing/About/About";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<HomePage/>} />
      <Route path="/pricing" element={<Pricing/>} />
      <Route path="/terms" element={<Terms/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/essays" element={<PrivateRoute><Main/></PrivateRoute>} />
      <Route path="/essays/create-essay" element={<PrivateRoute><EssayPage /></PrivateRoute>} />
      <Route path="/essays/results/:id" element={<PrivateRoute><EssayPageResults/></PrivateRoute>} />
      <Route path="/essays/purchase-credits" element={<PrivateRoute><Replenish/></PrivateRoute>} />
      <Route path="/essays/purchase-credits/complete" element={<PrivateRoute><SuccessPayment/></PrivateRoute>} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="*" element={<>404</>} />
    </Route>
  )
);
