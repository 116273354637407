import SignUpForm from "./SignUpForm";
import useSignUp from "./useSignUp";
import { useTranslation } from "react-i18next";
import useLoginGoogle from "../login/useLoginGoogle";
import useLoginFacebook from "../login/useLoginFacebook";

const SignUp = () => {
  const createMutation = useSignUp();
  const {t} = useTranslation("common");
  const googleLoginMutation = useLoginGoogle();
  const facebookLoginMutation = useLoginFacebook();

  const handleSubmit = (data) => {
    createMutation.mutate({
      username: data.username,
      email: data.email,
      password: data.password,
    });
  };

  const handleGoogleLogin = (response) => {
    googleLoginMutation.mutate({
      token: response.credential
    })
  };

  const handleFacebookLogin = (response) => {
    facebookLoginMutation.mutate({
      token: response.accessToken
    })
  };

  return (
    <div className="container">
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">
                    {t('signUp.title')}
                    </h5>
                  </div>
                  <SignUpForm onSubmit={handleSubmit} handleGoogleLogin={handleGoogleLogin} handleFacebookLogin={handleFacebookLogin} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default SignUp;
