import { NavLink } from "react-router-dom";
import Navigation from "../Navigation/Navigation";
import Accordion from "react-bootstrap/Accordion";
import Footer from "../Footer/Footer";
import "../utilities.scss";
import "./HomePage.scss";
import PlayIcon from "../../components/icons/PlayIcon";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoUrl = "https://www.youtube.com/embed/fydAxZ1mJGU";

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Navigation />
      <section className="bg-black sectionLanding pb-0">
        <div className="u-mb-58">
          <div className="u-d-flex  u-d-lg-none justify-content-center">
            <img
              src="images/main_logo.png"
              width={235}
              height={152}
              alt="logo"
            />
          </div>
          <h1 className="heading1 u-text-center u-mt-16 u-text-white col-lg-8 col-md-9 u-m-auto u-mb-105 u-mb-sm-40">
            Empowered Essays
            <br />
            <span className="heading1Description">
              Your Personal GAMSAT Coach
            </span>
          </h1>
          <div className="d-flex justify-content-center align-items-start flex-wrap">
            <button className="u-bg-blue button signUpButton mb-3">
              <NavLink
                className="nav-link text-white u-bold nav-link-large-text"
                to="/signup"
              >
                Sign Up
              </NavLink>
            </button>
            <button
              className="text-white button bg-black u-bold d-flex align-items-center"
              onClick={openModal}
            >
              <PlayIcon/>
              <span className="u-ml-10">Play Video</span>
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-center u-h-main-bg">
          <img src="images/bg.png" className="image-bg" alt="" loading="lazy"/>
        </div>
        <Modal show={isModalOpen} onHide={closeModal} centered size="lg" dialogClassName="no-padding-modal">
          <Modal.Body>
            <iframe
              className="youtube-iframe"
              width="100%"
              src={videoUrl}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ border: 'none', margin: 0, padding: 0, display: 'block' }}
            ></iframe>
          </Modal.Body>
        </Modal>
      </section>
      <section className="bg-black sectionLanding pt-0 box-shadow">
        <p className="info-label u-mb-30">Main</p>
        <div className="u-mb-40 z-index-10">
          <h1 className="heading1 u-mt-16 u-bold u-text-white">Features</h1>
        </div>
        <div className="d-grid lg:grid-cols-3 gap-5 grid-rows-3">
          <div className="u-rounded u-bg-link-water">
            <div className="info-card d-flex flex-column justify-content-between lg:h-[539px] sm:w-[80%]">
              <h3 className="heading2 u-bold">
                Reliable & Intelligent Insights
              </h3>
              <p className="body3">
                Michael & Lana – both of whom scored 91 in S2 – are the only
                people who worked on LikeMike™’s dataset. What they see,
                LikeMike™ sees.
              </p>
            </div>
          </div>

          <div className="u-rounded card2-bg">
            <div className="info-card d-flex flex-column justify-content-between lg:h-[539px] sm:w-[80%]">
              <h3 className="heading2 u-bold">Instant Feedback & Analysis</h3>
              <p className="body3">
                Get feedback on-demand, when you need it - late at night, first
                thing in the morning. LikeMike is always ready to help.
              </p>
            </div>
          </div>

          <div className="u-rounded card3-bg">
            <div className="info-card d-flex flex-column justify-content-between lg:h-[539px] sm:w-[80%]">
              <h3 className="heading2 u-bold">
                Private & Confidential Guidance
              </h3>
              <p className="body3">
                Getting feedback in S2 can be a vulnerable and uncomfortable
                process. LikeMike™ gives you access to the best minds, all in
                the privacy of your own space.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black sectionLanding">
        <p className="info-label u-mb-30">What is LikeMike™</p>
        <div className="heading-colored">
          <h3 className="u-mb-30">
            After scoring 91 in S2 in 2021 (and 82 overall), Michael went on to
            write 7 books and over two-theses worth of material on Section II,
            helping nearly 5000 students to master Section II. Six sittings in a
            row one of his personal students scored over 90 (including Lana),
            and approximately 40% of his students score at the 99th percentile,
            regardless of where they start.
          </h3>
          <h3>
            Michael & Lana trained LikeMike™ on over 6000 essay corrections, 450
            transcripts of videos of Michael teaching, and everything he has
            written on Section II to produce exactly what Michael does in
            response to an essay, on demand, for a fraction of the price.
          </h3>
        </div>
      </section>
      <section className="u-bg-gradient sectionLanding">
        <p className="info-label u-mb-30">How it Works</p>
        <div className="row align-items-baseline">
          <div className="col-lg-6">
            <div className="u-mb-40">
              <h1 className="heading1 u-mt-16 u-bold u-text-white">
                Step by Step
              </h1>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="container u-pl-lg-0">
              <div className="step completed u-pl-lg-0">
                <div className="v-stepper">
                  <div className="circle one"></div>
                  <div className="line"></div>
                </div>

                <div className="u-ml-50">
                  <h3 className="heading2 u-bold u-text-white">
                    Submit your Plan & Essay
                  </h3>
                  <p className="body3 u-text-white">
                    You can write them “live” or upload a previously written
                    essay and plan.
                  </p>
                </div>
              </div>

              <div className="step completed u-pl-lg-0">
                <div className="v-stepper">
                  <div className="circle two"></div>
                  <div className="line"></div>
                </div>

                <div className="u-ml-50">
                  <h3 className="heading2 u-bold u-text-white">
                    Process & review
                  </h3>
                  <p className="body3 u-text-white">
                    LikeMike™ will follow Michael’s chain of reasoning to scan
                    your plan and essay for common S2 errors, weight and
                    prioritise them and tell you which you should focus on and
                    what to do about it.
                  </p>
                </div>
              </div>

              <div className="step completed u-pl-lg-0">
                <div className="v-stepper">
                  <div className="circle three"></div>
                  <div className="line"></div>
                </div>

                <div className="u-ml-50">
                  <h3 className="heading2 u-bold u-text-white">
                    Study & Improve
                  </h3>
                  <p className="body3 u-text-white">
                    LikeMike™ will give you both personalised feedback as well
                    as direct you to free 90+ resources that have the solutions
                    to the problems you’re facing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black sectionLanding">
        <p className="info-label u-mb-30">FAQ</p>
        <div className="u-mb-40">
          <h1 className="heading1 u-mt-16 u-bold u-text-white">
            Popular Questions
          </h1>
        </div>
        <Accordion defaultActiveKey={["0"]} alwaysOpen>
          <hr className="u-text-white" />
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <span className="Button Button--toggler">
                <span className="Button-plus">
                  <span className="Button-plusHorizontal"></span>
                  <span className="Button-plusVertical"></span>
                </span>
              </span>
              <p>How does LikeMike™ work?</p>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                LikeMike™ uses the latest advancements in AI, in combination
                with an enormous Section II data set of essays corrections,
                transcripts from Michael's tutorials, videos, and careful
                processing and adjustment by Michael and his team, to produce
                feedback and scoring that is true to the feedback Michael gives
                his personal students.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <hr className="u-text-white" />
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <span className="Button Button--toggler">
                <span className="Button-plus">
                  <span className="Button-plusHorizontal"></span>
                  <span className="Button-plusVertical"></span>
                </span>
              </span>
              <p>How accurate is LikeMike™</p>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                To what Michael would say: extremely. LikeMike™’s textual
                feedback comes straight out of Michael’s mouth: from his 7
                books, over 450 transcripts of him teaching his personal
                students, and from over 6000 hand graded essay corrections with
                extensive feedback taken from his years of grading essays; all
                then optimised through multiple iterations to fine tune and
                improve the results; and then laid over the best ‘Large Language
                Model’ in the world.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <hr className="u-text-white" />
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <span className="Button Button--toggler">
                <span className="Button-plus">
                  <span className="Button-plusHorizontal"></span>
                  <span className="Button-plusVertical"></span>
                </span>
              </span>
              <p>What does the feedback look like?</p>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                It changes every couple of weeks as that is roughly the
                currently turnaround between new updates and versions of
                LikeMike™. Presently it provides approximately 500-600 words of
                textual feedback divided across the main areas of Section II
                performance (Macrostructure/Planning, Microstructure, Ideas,
                Clarity) as well as a “Feedback from Michael” section which
                focusses less on technical aspects and more on producing
                something more like what I literally have said to essays similar
                to yours. LikeMike™ tells you the single thing that will improve
                your mark if changed, and recommends resources and solutions to
                the problems you’re experience.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <hr className="u-text-white" />
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <span className="Button Button--toggler">
                <span className="Button-plus">
                  <span className="Button-plusHorizontal"></span>
                  <span className="Button-plusVertical"></span>
                </span>
              </span>
              <p>Will LikeMike™ mark the same as ACER?</p>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                We're not entirely sure yet - but this won't impact its utility
                to you. At this stage ACER scored essays with the corresponding
                marks are a minority of the data set. We may provide a way to
                upload anonymous data to contribute to LikeMike's™ training in
                the future however at this stage what is more relevant is
                whether you get useful and beneficial training information.
                Knowing exactly where you are going right and wrong, and knowing
                what to do about it, will increase your mark independant of how
                accurate it is to what ACER would have said. 90plusgamsat
                achieves its results not by being accurate to the fine tuning of
                what ACER would score, but by being accurate in what needs to
                change to improve. This LikeMike™ does perfectly. At this stage,
                due to extraordinary challenges getting it accurate to the dot
                numerically, as well as to respond in line with Michael’s
                philosophy of having students focus on building foundations, not
                reaching for results, LikeMike™’s numerical grading is behind
                the scenes and not displayed to students.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <hr className="u-text-white" />
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <span className="Button Button--toggler">
                <span className="Button-plus">
                  <span className="Button-plusHorizontal"></span>
                  <span className="Button-plusVertical"></span>
                </span>
              </span>
              <p>Is this a generic GPT style AI</p>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Absolutely not. LikeMike™ is a complex neural network that has
                been trained and developed to understand the nuances that
                Michael & Lana see in GAMSAT essays. The guidance an AI gives
                can only be as good as the data its trained on. The trick is to
                curate what the AI is trained on in ways that give it a
                ‘specialist’ understanding, rather than a generic understanding.
                Chat GPT has a general understanding of essays and writing.
                90plusgamsat is concerned with elite-level performance in a
                GAMSAT Section II context only. So, LikeMike™ is a specialist on
                two minds: Michael and Lana’s.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <hr className="u-text-white" />
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              <span className="Button Button--toggler">
                <span className="Button-plus">
                  <span className="Button-plusHorizontal"></span>
                  <span className="Button-plusVertical"></span>
                </span>
              </span>
              <p>How does LikeMike™ compare to other essay marking AI’s</p>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                An AI will be precisely as good as the data it’s trained on. You
                can expect it to perform at a 90plus level.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <hr className="u-text-white" />
          <Accordion.Item eventKey="6">
            <Accordion.Header>
              <span className="Button Button--toggler">
                <span className="Button-plus">
                  <span className="Button-plusHorizontal"></span>
                  <span className="Button-plusVertical"></span>
                </span>
              </span>
              <p>Does this give some students an unfair advantage?</p>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Well yes, and no. In one sense it's a clear advantage that
                another student might not have - sure! In another sense, prior
                to LikeMike's™ creation the supply/demand was pushing the cost
                of tutoring with 90plusgamsat up, and limiting our ability to
                stay on top of keeping up with marking every essay for free in
                the Section II Sorted group on Facebook. LikeMike™ makes premium
                tuition available to far more students than ever before. For
                those who cannot afford even this reduced fee, 90plusgamsat will
                always remain committed to keeping avenues open for free tuition
                to students who contribute to others around them as well.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <hr className="u-text-white" />
          <Accordion.Item eventKey="7">
            <Accordion.Header>
              <span className="Button Button--toggler">
                <span className="Button-plus">
                  <span className="Button-plusHorizontal"></span>
                  <span className="Button-plusVertical"></span>
                </span>
              </span>
              <p>What if I try it and I'm not happy?</p>
            </Accordion.Header>
            <Accordion.Body>
              <p>
                You are protected by 90plusgamsat's better than risk free
                guarantee: a full refund plus 15%. No BS.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </section>
      <section className="bg-black sectionLanding p-0">
        <Footer />
      </section>
    </>
  );
};
export default HomePage;
