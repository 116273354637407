import React from 'react';

const WordCounter = ({ value }) => {
  const wordCount = value ? value.trim().split(/\s+/).length : 0;
  
  return (
    <p className="word-count mt-2">words: {wordCount}</p>
  );
};

export default WordCounter;