import { getCreateOrderResult } from "./ReplenishServices";
import { useQuery } from "react-query";
import { ORDER_RESULT } from "../constants";

const useGetCreateOrderResult = (id) => {

  return useQuery(
    [ORDER_RESULT, id],
    () => getCreateOrderResult(id), {retry: 1},
    {
      enabled: !!id,
    }
  );
};

export default useGetCreateOrderResult;
