import { useTranslation } from "react-i18next";
import WordCounter from "./WordCounter";
import "./Essay.scss";

const EssayPlan = ({ register, errors, watch }) => {
  const { t } = useTranslation("common");

  return (
    <>
      <div className="row mb-3">
        <label htmlFor="contention" className="col-sm-2 col-form-label">
          {t("form.contention")}
        </label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            {...register("contention", { required: true })}
            placeholder={"Type the contention of an essay"}
            style={{ height: "60px" }}
          ></textarea>
          <div className="d-flex justify-content-between">
            <div className="mt-2">
              {errors?.contention?.type === "required" && (
                <p className="error">{t("form.error")}</p>
              )}
            </div>
            <WordCounter value={watch("contention")} />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="topic_sentence_1" className="col-sm-2 col-form-label">
          {t("form.topicSentences1")}
        </label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            {...register("topic_sentence_1", { required: true })}
            placeholder={"Type the topic sentences 1 of an essay"}
            style={{ height: "60px" }}
          ></textarea>
          <div className="d-flex justify-content-between">
            <div className="mt-2">
              {errors?.topic_sentence_1?.type === "required" && (
                <p className="error">{t("form.error")}</p>
              )}
            </div>
            <WordCounter value={watch("topic_sentence_1")} />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="topic_sentences_2" className="col-sm-2 col-form-label">
          {t("form.topicSentences2")}
        </label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            {...register("topic_sentences_2", { required: true })}
            placeholder={"Type the topic sentences 2 of an essay"}
            style={{ height: "60px" }}
          ></textarea>
          <div className="d-flex justify-content-between">
            <div className="mt-2">
              {errors?.topic_sentences_2?.type === "required" && (
                <p className="error">{t("form.error")}</p>
              )}
            </div>
            <WordCounter value={watch("topic_sentences_2")} />
          </div>
        </div>
      </div>
    </>
  );
};
export default EssayPlan;
