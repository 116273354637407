import { NavLink } from "react-router-dom";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import "../utilities.scss";
import "./Pricing.scss";

const Pricing = () => {
  return (
    <div className="gradientBody">
      <div className="u-bg-pricing"></div>
      <Navigation logo={true} />
      <div>
        <section className="sectionLanding">
          <p className="info-label u-mb-30">Pricing</p>
          <div className="u-mb-40 z-index-10">
            <h1 className="heading1 u-mt-16 u-bold u-text-white">Plans</h1>
          </div>
          <div className="d-grid lg:grid-cols-3 gap-5 grid-rows-3">
            <div className="u-rounded price1-bg">
              <div className="info-card d-flex flex-column h-100 justify-content-between">
                <div>
                <h3 className="heading2 u-bold u-mb-40">
                  <span className="numbers">1</span> <br /> credit
                </h3>
                <p className="body3 u-mb-sm-15 u-mb-105">
                  A point in the right direction, with a roadmap of what to work
                  on
                </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="cost">$15</p>
                  <button className="u-bg-blue button buttonPrice">
                  <NavLink className="nav-link text-white u-bold nav-link-large-text" to="/essays/purchase-credits">
                    Buy
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>

            <div className="u-rounded price2-bg">
              <div className="info-card d-flex flex-column h-100 justify-content-between">
                <div>
                <h3 className="heading2 u-bold u-mb-40">
                  <span className="numbers">3</span>
                  <br /> credits
                </h3>
                <p className="body3 u-mb-sm-15 u-mb-105">
                  Complete oversight over your strengths and errors you didn’t
                  know you were making
                </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="cost">$39</p>
                  <button className="u-bg-blue button buttonPrice">
                  <NavLink className="nav-link text-white u-bold nav-link-large-text" to="/essays/purchase-credits">
                    Buy
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>

            <div className="u-rounded price3-bg">
              <div className="info-card d-flex flex-column h-100 justify-content-between">
                <div>
                <h3 className="heading2 u-bold u-mb-40">
                  <span className="numbers numbers-ten">10</span>
                  <br /> credits
                </h3>
                <p className="body3 u-mb-sm-15 u-mb-105">
                  The best value way to work with the A-team. Get ongoing
                  guidance and direction with clear feedback and analytics of
                  your progress, tailored tips and resources based on your
                  problem areas.
                </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="cost">$99</p>
                  <button className="u-bg-blue button buttonPrice">
                  <NavLink className="nav-link text-white u-bold nav-link-large-text" to="/essays/purchase-credits">
                    Buy
                    </NavLink>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
export default Pricing;
