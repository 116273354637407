import { useQuery } from "react-query";
import { ESSAY } from "../constants";
import { getEssayItem } from "./EssayService";

const useGetEssayItem  = (id) => {
  return useQuery([ESSAY, id], () => getEssayItem(id),
  {
    enabled: !!id
  });
};

export default useGetEssayItem;
