import { useMutation, useQueryClient } from "react-query";
import { ESSAYS, HttpStatus } from "../constants";
import { createEssay } from "./EssayService";
import { useSnackbar } from "notistack";

const useCreateEssay = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (data) => createEssay(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries(ESSAYS);
    },
    onError: (error) => {
      const getMessage = () => {
        if (error.response.status === HttpStatus.PAYMENT_REQUIRED) {
          return `You have no enough credits`;
        } else {
          return `${error.response.data.message}`;
        }
      };

      enqueueSnackbar(getMessage(), { variant: "error" });
    },
  });
};

export default useCreateEssay;
