import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SuccessIcon from "../components/icons/SuccessIcon";
import { NavLink } from "react-router-dom";
import "./Replenish.scss";
import useGetCreateOrderResult from "./useGetCreateOrderResult";

const SuccessPayment = () => {
  const { t } = useTranslation("common");
  let orderId = window.location.search.split("=")[1];
  const result = useGetCreateOrderResult(orderId).isSuccess;

  return (
    <main>
      <div className="card-success ">
        <div className="d-flex flex-column justify-content-center align-items-center p-4 rounded card-success-border h-100">
          <div className="pt-5">
            {result ? (
              <h5 className="text-center pb-0 fs-1 replenish-title">
                {t("replenish.successTitle")}
              </h5>
            ) : (
              <h5 className="text-center pb-0 fs-1 replenish-title">
                 {t("replenish.wait")}
              </h5>
            )}
          </div>
          <div className="pb-2">
            <p className="text-center pb-0 fs-3 replenish-description">
              {t("replenish.successOrderId", { orderId: orderId })}
            </p>
          </div>
          <div className="pb-4 spiner-contaner">
            {result ? (
              <SuccessIcon />
            ) : (
              <div
                className="spinner-success spinner-border"
                role="status"
              ></div>
            )}
          </div>

          <Button disabled={!result}>
            <NavLink to="/essays/create-essay" className="btn btn-blue card-button">
              {t("button.backToEssays")}
            </NavLink>
          </Button>
        </div>
      </div>
    </main>
  );
};
export default SuccessPayment;
