import { clientDataService } from "../config/axios";

export const getProductsList = async () => {
  return await clientDataService({
    url: `/api/credits/plans`,
    method: "GET"
  });
};

export const getCreateOrderResult = async (orderId) => {
  return await clientDataService({
    url: `/api/credits/check-order/${orderId}`,
    method: "GET"
  });
};
