import React, {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'

const ApexChart = ({ errorsHistory }) => {
  const [options, setOptions] = useState({
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false,
        },
        background: '#ffffff',
      },
      markers: {
        size: 4
      },
      colors: ['#FF771D', '#2ECA6A', '#4154F1'],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.4,
          stops: [0, 90, 100]
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: function(val) {
            return typeof val === "string"
            ? val.length > 10 ? val.substring(0, 10) + '...' : val
            : val
          }
        }
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      }
    })
  const [series, setSeries] = useState([])

  useEffect(() => {
    if (errorsHistory) {
      const keys = Object.keys(errorsHistory)
      const isFirstHistory = keys.length === 1
      let categories, majorErrors, mediumErrors, minorErrors

      if (isFirstHistory) {
        const key = keys[0]
        categories = ['No Data', `Essay ${key}`]
        majorErrors = [0, errorsHistory[key]?.errors?.Major || 0]
        mediumErrors = [0, errorsHistory[key]?.errors?.Medium || 0]
        minorErrors = [0, errorsHistory[key]?.errors?.Minor || 0]
      } else {
        const lastHistories = keys.slice(-15)
        categories = lastHistories.map(key => errorsHistory[key]?.theme || `Essay ${key}`)
        majorErrors = lastHistories.map(key => errorsHistory[key]?.errors?.Major || 0)
        mediumErrors = lastHistories.map(key => errorsHistory[key]?.errors?.Medium || 0)
        minorErrors = lastHistories.map(key => errorsHistory[key]?.errors?.Minor || 0)
      }

      setSeries([
        { name: 'Major Errors', data: majorErrors },
        { name: 'Medium Errors', data: mediumErrors },
        { name: 'Minor Errors', data: minorErrors },
      ])

      setOptions(prevOptions => ({
        ...prevOptions,
        tooltip: {
          x: {
            formatter: function(value, { seriesIndex, dataPointIndex, w }) {
              return categories[dataPointIndex]
            }
          }
        },
        xaxis: {
          ...prevOptions.xaxis,
          categories: categories.map(
            category => category.length > 10
              ? category.substring(0, 10) + '...'
              : category)
        }
      }))
    }
  }, [errorsHistory])

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height={350}
    />
  )
}

export default ApexChart
