import { NavLink } from "react-router-dom";
import JournalIcon from "../components/icons/JournalIcon";
import "./EssayItem.scss";

const EssayItem = ({ name, url }) => {

  return (
    <li
      className="nav-item mb-2"
    >
      <NavLink className="nav-link" to={url}>
        <JournalIcon />
        <span className="ms-2 essay-item">{name}</span>
      </NavLink>
    </li>
  );
};

export default EssayItem;
