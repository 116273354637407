import { useTranslation } from "react-i18next";
import LoginForm from "./LoginForm";
import useLogin from "./useLogin";
import useLoginFacebook from "./useLoginFacebook";
import useLoginGoogle from "./useLoginGoogle";

const Login = () => {
  const createMutation = useLogin();
  const googleLoginMutation = useLoginGoogle();
  const facebookLoginMutation = useLoginFacebook();
  const { t } = useTranslation("common");

  const handleSubmit = (data) => {
    createMutation.mutate({
      email: data.email,
      password: data.password,
    });
  };

  const handleGoogleLogin = (response) => {
    googleLoginMutation.mutate({
      token: response.credential
    })
  };

  const handleFacebookLogin = (response) => {
    facebookLoginMutation.mutate({
      token: response.accessToken
    })
  };
  
  return (
    <div className="container">
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">
                      {t("login.title")}
                    </h5>
                  </div>
                  <LoginForm onSubmit={handleSubmit} handleGoogleLogin={handleGoogleLogin} handleFacebookLogin={handleFacebookLogin} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Login;
