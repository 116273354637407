import { clientDataService } from "../config/axios";

export const getEssaysList = async () =>{
  return await clientDataService({
    url: `/api/essays/my`,
    method: 'GET',
  });
}

export const getEssayItem = async (id) =>{
  return await clientDataService({
    url: `/api/essays/${id}`,
    method: 'GET',
  });
}

export const createEssay = async (data) =>{
  return await clientDataService({
    url: `/api/essays/submit`,
    method: 'POST',
    data: data
  });
}
