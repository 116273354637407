import { NavLink } from "react-router-dom";
import "../utilities.scss";
import "./Footer.scss";

const Footer = () => {
  return (
      <div className="footer">
        <div className="row u-mb-40">
          <div className="col-md-7">
            <h2 className="heading3 u-bold u-mb-105 u-mb-sm-90">
              Featured by VICE and The University of Melbourne. We are always
              looking for great people.
            </h2>
            <p className="col-md-8 body2 u-bold opacity-50">
              © 2023 90plusgamsat Pty Ltd. All rights reserved 90+ GAMSAT™,
              LikeMike™, and the 90plus kid™ are trademarks of 90plusgamsat Pty
              Ltd.
            </p>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-md-7 d-flex flex-wrap u-flex-sm-column">
            <NavLink className="nav-link u-bold u-mb-10" to="/">Home</NavLink>
            <NavLink className="nav-link u-bold u-ml-30 u-ml-sm-0 u-mb-10" to="/about">About Us</NavLink>
            <NavLink className="nav-link u-bold u-ml-30 u-ml-sm-0 u-mb-10" to="/pricing">Pricing</NavLink>
            <NavLink className="nav-link u-bold u-ml-30 u-ml-sm-0 u-mb-10" to="/terms">Terms & Conditions</NavLink>
          </div>
          <div className="col-md-5 d-flex justify-content-start justify-content-md-end u-mt-sm-50">
            <a href="https://www.facebook.com/groups/90plussection2sorted/" target="_blank">
            <img src="images/social1.svg" alt="social" loading="lazy"/>
            </a>
            <a href="https://chat.whatsapp.com/EnZxXwLeuB4KTxroK81ZRP" target="_blank">
            <img src="images/social2.svg" alt="social" className="u-ml-50" loading="lazy" />
            </a>
            <a href="https://discord.com/invite/FDNqhD6zkB" target="_blank">
            <img src="images/social3.svg" alt="social" className="u-ml-50" loading="lazy" />
            </a>
          </div>
        </div>
      </div>
  );
};
export default Footer;
