import React from 'react'
import { Navigate } from 'react-router-dom'
import useMe from '../me/useMe'

export const PrivateRoute = ({ children}) => {
  const meQuery = useMe()
  if (window.location.pathname === "/essays/purchase-credits/complete") {
    if(meQuery.isError) {
      let orderId = window.location.search.split("=")[1];
      localStorage.setItem("purchase", orderId)
    }
    else {
      localStorage.removeItem("purchase")
    }
  }

  return meQuery.isError ? <Navigate to="/login" replace /> : children;
};
