import React from "react";
import './SamcartPopup.scss'

const SamcartCheckoutPopup = ({ slug, setDisplayed }) => {

  return (
    <div
      className="modal fade show popup"
      id="basicModal"
      tabIndex="-1"
    >
      <div className="modal-dialog">
        <div className="modal-content">

          <div className="modal-body">
          <div className="d-flex row justify-content-end mx-1 mb-1">
          <button type="button" className="btn-close" onClick={() => setDisplayed(false)} aria-label="Close"></button>
          </div>
          <sc-checkout product={slug} subdomain="ninepg" coupon=""></sc-checkout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SamcartCheckoutPopup;
