import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_en from "./translations/en/common.json";
import { GoogleOAuthProvider } from '@react-oauth/google';

i18next.init({
    interpolation: { escapeValue: false }, 
    lng: 'en',
    resources: {
        en: {
            common: common_en
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
  <React.StrictMode>
     <I18nextProvider i18n={i18next}>
    <App />
    </I18nextProvider>
  </React.StrictMode>
  </GoogleOAuthProvider>
);