import { useForm } from "react-hook-form";
import "./SignUpForm.scss";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import LoginIntegrations from "../login/LoginIntegrations";
import {useEffect, useState} from 'react';

const SignUpForm = ({ onSubmit, handleFacebookLogin, handleGoogleLogin }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      email: "",
      password: "",
    },
  });

  const {t} = useTranslation("common");

  // TODO try to use useGoogleLogin from @react-oauth/google to have ability to change styles
  const [buttonWidth, setButtonWidth] = useState(250)

  useEffect(() => {
    const validationElement = document.querySelector('.needs-validation')
    if (validationElement) {
      setButtonWidth(validationElement.offsetWidth)
    }
  }, [])

  return (
    <form
      className="row g-3 needs-validation"
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
    >
      <div className="col-12">
        <label htmlFor="yourName" className="form-label">
        {t('form.name')}
        </label>
        <input
          type="text"
          name="username"
          className="form-control"
          id="yourName"
          {...register("username", { required: true })}
        />
        {errors?.username?.type === "required" && (
          <p className="error">{t('form.error')}</p>
        )}
      </div>

      <div className="col-12">
        <label htmlFor="yourEmail" className="form-label">
        {t('form.email')}
        </label>
        <input
          type="email"
          name="email"
          className="form-control"
          id="yourEmail"
          {...register("email", { required: true })}
        />
        {errors?.email?.type === "required" && (
          <p className="error">{t('form.error')}</p>
        )}
      </div>
      <div className="col-12">
        <label htmlFor="yourPassword" className="form-label">
        {t('form.password')}
        </label>
        <input
          type="password"
          name="password"
          className="form-control"
          id="yourPassword"
          {...register("password", { required: true })}
        />
        {errors?.password?.type === "required" && (
          <p className="error">{t('form.error')}</p>
        )}
      </div>

      <div className="col-12">
        <button className="btn btn-blue w-100" type="submit">
          {t('button.signUp')}
        </button>
      </div>
      <LoginIntegrations
        handleGoogleLogin={handleGoogleLogin}
        handleFacebookLogin={handleFacebookLogin}
        buttonWidth={buttonWidth}
      />
      <div className="col-12 mt-0">
       <hr/>
        <div className="d-flex justify-content-center">
          <a className="link w-100" href="/login">
            <Button className="btn loginButton text-center text-center w-100">
            {t('signUp.login')}
            </Button>
          </a>
        </div>
        <p className="small text-center mt-2">  <a href="mailto:90plusgamsat@gmail.com">{t("login.restorePassword")}</a></p>
      </div>
    </form>
  );
};
export default SignUpForm;
