import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import "../utilities.scss";
import "./About.scss";

const About = () => {
  return (
    <div className="bg-black">
      <Navigation logo={true} />
      <section className="sectionLanding pb-0">
        <div className="u-mb-40">
          <h1 className="heading1 u-mt-16 u-bold u-text-white">About Us</h1>
        </div>
      </section>
      <div className="u-bg-gradient">
        <section className="sectionLanding">
          <img src="images/logo_about.svg" alt="logo" className="image" />
          <p className="bodyDescription">
            LikeMike™ is powered by 90plusgamsat – a premium pre-medical
            mentoring service offering free and paid resources that take the
            pain out of GAMSAT. 90plusgamsat specialises in helping students who
            have tried everything else and are yet to be successful: there’s
            always a way - we find it.
          </p>
        </section>
      </div>
      <section className="sectionLanding bg-black">
        <p className="info-label u-mb-30">A Note from</p>
        <div className="u-mb-40 z-index-10">
          <h1 className="heading1 u-mt-16 u-bold u-text-white">Michael</h1>
        </div>
        <div className="row justify-content-between">
          <div className="col-md-5 order-md-1 order-2 ">
            <p className="body3 u-text-white">
              Back in February 2020, I made a life-altering decision. I walked
              away from a lucrative insurance sales job, earning a
              quarter-million dollars annually. Why? I yearned for a life
              centered around giving, not just receiving. I had tasted success,
              but it felt hollow. I pondered my true calling and realized I had
              a knack for teaching or medicine. I chose the latter. My first
              hurdle? The GAMSAT. I immersed myself in rigorous study sessions,
              sometimes stretching up to 18 hours a day for six months. My
              dedication bore fruit when I secured a spot in the Doctor of
              Medicine program at Unimelb.
            </p>
            <p className="body3 u-text-white">
              My GAMSAT journey wasn't smooth. I encountered misleading advice
              and faced numerous challenges. Even with seven tutors and multiple
              courses, I felt the guidance was limited, often targeting a score
              of 75. The roadmap to exceptional performance seemed non-existent.
              I had to carve my own path.{" "}
            </p>
            <p className="body3 u-text-white">
              {" "}
              I believe no one should tread this challenging path blindly.
              Instead of spending countless hours deciphering the GAMSAT puzzle,
              I wish for you to cherish moments with loved ones or perfect your
              skills. That's why I'm sharing my insights, hoping to illuminate
              your journey.
            </p>

            <p className="body3 u-text-white">
              I'm here to mentor and share the wisdom I've amassed, helping you
              transition from a good score to an elite one. As the 90plusgamsat
              community flourished, we recognized the need to extend our unique
              blend of altruism, honesty, and expertise to all GAMSAT sections
              and even interviews. It took time to gather a team that resonated
              with our core values, but we've achieved it. Now, we're proud to
              offer comprehensive guidance for the entire pre-medical journey,
              ensuring you face fewer obstacles.
            </p>
            <p className="body3 u-text-white">
              Thank you for entrusting me with your aspirations. Witnessing your
              dreams unfold, thanks to your dedication and our teachings, fills
              me with immense pride.
            </p>
            <img src="images/name.svg" alt="sign" className="image" loading="lazy" />
          </div>
          <div className="col-md-5 order-md-2 order-1 u-mb-40">
            <img src="images/michael.png" className="image" alt="Michael" loading="lazy" />
          </div>
        </div>
      </section>
      <section className="bg-black sectionLanding p-0">
        <Footer />
      </section>
    </div>
  );
};
export default About;
