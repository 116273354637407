import { QueryClientProvider, QueryClient } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import './App.scss';
import { SnackbarProvider } from 'notistack'
import { router } from './routes/Routes';

const App = () => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
        <SnackbarProvider
        maxSnack={1}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
    <RouterProvider router={router} />
    </SnackbarProvider>
    </QueryClientProvider>
  );
}
export default App;
