import { Button } from "react-bootstrap";
import "./Replenish.scss";
import { useState } from "react";
import SamcartCheckoutPopup from "../samcart/SamcartPopup";
import { useTranslation } from "react-i18next";

const ReplenishItem = ({ credits, slug, price }) => {
  const [displayed, setDisplayed] = useState(false);
  const { t } = useTranslation("common");

  return (
    <div className="col-md-4 mt-3">
      <div className="card card-radius d-flex flex-column h-100">
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="card-cost">{`$${price / 100}`}</h4>
            <div className="card-badge">
              <p className="m-0 card-credits">{credits}</p>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <p className="card-text">
            {t("replenish.cardText")}{" "}
            <span className="card-credits">
              {Number(credits.toString().match(/\d+/)[0]) > 1
                ? t("replenish.essays", {
                    count: Number(credits.toString().match(/\d+/)[0]),
                  })
                : t("replenish.essay", {
                    count: Number(credits.toString().match(/\d+/)[0]),
                  })}
            </span>{" "}
          </p>
        </div>
        <Button
          className="btn btn-blue credits card-button"
          data-bs-toggle="modal"
          data-bs-target="#basicModal"
          onClick={() => {
            setDisplayed(true);
          }}
        >
          {t("button.purchase")}
        </Button>
      </div>
      {displayed && (
        <SamcartCheckoutPopup setDisplayed={setDisplayed} slug={slug} />
      )}
    </div>
  );
};
export default ReplenishItem;
