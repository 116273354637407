import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import LoginIntegrations from "./LoginIntegrations";
import "./LoginForm.scss";
import {useEffect, useState} from 'react';

const LoginForm = ({ onSubmit, handleGoogleLogin, handleFacebookLogin }) => {
  const { t } = useTranslation("common");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // TODO try to use useGoogleLogin from @react-oauth/google to have ability to change styles
  const [buttonWidth, setButtonWidth] = useState(250)

  useEffect(() => {
    const validationElement = document.querySelector('.needs-validation')
    if (validationElement) {
      setButtonWidth(validationElement.offsetWidth)
    }
  }, [])

  return (
    <form
      className="row g-3 needs-validation"
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
    >
      <div className="col-12">
        <label htmlFor="yourEmail" className="form-label">
          {t("form.email")}
        </label>
        <input
          type="email"
          name="email"
          className="form-control"
          {...register("email", { required: true })}
          id="yourEmail"
        />
      </div>
      {errors?.email?.type === "required" && (
        <p className="error">{t("form.error")}</p>
      )}
      <div className="col-12">
        <label htmlFor="yourPassword" className="form-label">
          {t("form.password")}
        </label>
        <input
          type="password"
          name="password"
          className="form-control"
          {...register("password", { required: true })}
          id="yourPassword"
        />
      </div>
      {errors?.password?.type === "required" && (
        <p className="error">{t("form.error")}</p>
      )}
      <div className="col-12">
        <button className="btn btn-blue w-100" type="submit">
          {t("button.login")}
        </button>
      </div>
      <LoginIntegrations
        handleGoogleLogin={handleGoogleLogin}
        handleFacebookLogin={handleFacebookLogin}
        buttonWidth={buttonWidth}
      />
      <div className="col-12 mt-0">
        <hr />
        <div className="d-flex justify-content-center">
          <a className="link w-100" href="/signup">
            <Button className="btn loginButton text-center text-center w-100">
              {t("login.createAccount")}
            </Button>
          </a>
        </div>
        <p className="small text-center mt-2">  <a href="mailto:90plusgamsat@gmail.com">{t("login.restorePassword")}</a></p>
      </div>
    </form>
  );
};
export default LoginForm;
