import { useState } from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BurgerMenuIcon from "../components/icons/BurgerMenuIcon";
import Profile from "../components/icons/Profile";
import "./Navigation.scss";

const Navigation = ({ handleBurgerNav, me, handleLogOut }) => {
  const { t } = useTranslation("common");
  const [profileToggle, setProfileToggle] = useState(false);

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <div className="logo d-flex align-items-center">
          <div className="logo d-none d-lg-block ps-2">
          <NavLink to="/essays">
            <img src="/images/Logo1.svg" alt="logo" width={183} height={88} />
            </NavLink>
          </div>
        </div>
        <Button
          onClick={handleBurgerNav}
          className="navbar-burger btn btn-light  flex items-center"
        >
          <BurgerMenuIcon />
        </Button>
      </div>

      <nav className="header-nav ms-auto">
        <div className="d-flex align-items-center">
          {!me.unlimited_credits_to ? (
            <span className="d-none d-md-block">
              {t("navigation.credits", { count: me.credits })}
            </span>
          ) : (
            <p className="d-none d-md-block unlimited-text">
              You have <span className="unlimited">Unlimited</span> credits 🚀
            </p>
          )}
          {!me.unlimited_credits_to && (
            <NavLink to="/essays/purchase-credits">
              <Button type="button" className="btn btn-blue credits mx-4">
                {t("button.replenish")}
              </Button>
            </NavLink>
          )}
          <div className="nav-item dropdown pe-3">
            <div
              className="nav-link nav-profile d-flex align-items-center pe-0"
              onClick={() => setProfileToggle((prev) => !prev)}
            >
              <Profile />
              <span className="d-none d-md-block ps-2 dropdown-toggle">
                {me.username}
              </span>
            </div>
            <ul
              className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile dropdown-profile ${
                profileToggle ? "d-block" : "d-none"
              }`}
            >
              <li className="dropdown-header">
                <h6>{me.username}</h6>
                {!me.unlimited_credits_to ? (
                  <span className="d-none d-md-block">
                    {t("navigation.credits", { count: me.credits })}
                  </span>
                ) : (
                  <p className="d-none d-md-block unlimited-text">
                    You have <span className="unlimited">Unlimited</span>{" "}
                    credits 🚀
                  </p>
                )}
              </li>

              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="https://docs.google.com/forms/d/1Tk43jdWyTZxHvfXz0-UvnRyWEqwMzfD_CkOGhhK0rnA/edit"
                  target="_blank"
                >
                  <i className="bi bi-gear"></i>
                  <span>{t("navigation.addFeedback")}</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <div
                  className="dropdown-item d-flex align-items-center cursor-pointer"
                  onClick={handleLogOut}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>{t("button.logOut")}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navigation;
