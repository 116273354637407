import { useTranslation } from "react-i18next";
import useGetDashboard from "../dashboard/useGetDashboard";
import "./Dashboard.scss";
import ApexChart from "./DashboardLineChart";
import { isEmpty } from "lodash";

const Dashboard = () => {
  const { t } = useTranslation("common");
  const dashboardQuery = useGetDashboard();
  const { isLoading, data } = dashboardQuery;

  function getTimeSinceLastAction(date) {
    const now = new Date();
    const lastActionDate = new Date(date);
    const diff = now - lastActionDate;

    const intervals = [
      { label: "year", duration: 1000 * 60 * 60 * 24 * 365 },
      { label: "month", duration: 1000 * 60 * 60 * 24 * 30 },
      { label: "day", duration: 1000 * 60 * 60 * 24 },
      { label: "hour", duration: 1000 * 60 * 60 },
      { label: "minute", duration: 1000 * 60 },
    ];
    const interval = intervals.find(({ duration }) => diff >= duration);

    if (interval) {
      const value = Math.floor(diff / interval.duration);
      const label = `${interval.label}${value === 1 ? "" : "s"}`;

      return `${value} ${label} ago`;
    }

    return "recently";
  }

  const arrayToHtml = (data) => {
    let html = "";
    data.forEach((item) => {
      html += `<h6 style="font-weight: bold;">Theme: ${item.theme}</h5>`;
      if (!item.errors.includes(null) && item.errors.length !== 0) {
        html += "<ol>";
        item.errors.forEach((error) => {
          Object.keys(error).forEach((errorKey) => {
            if (!isEmpty(error[errorKey])) {
              html += `<li>${errorKey}: ${error[errorKey]}</li><br/>`;
            }
          });
        });
        html += "</ol>";
      } else if (item.feedback_status && item.errors.length === 0) {
        html += `<p style="color: darkorange; font-weight: bold;">Your feedback in process: ${item.feedback_status}...</p>`;
      } else {
        html += `<p style="color: green; font-weight: bold;">Good work, you didn’t make any errors in an essay ${item.theme}!</p>`;
      }
    });
    return html;
  };

  if (isLoading) {
    return (
      <div className="spiner-container d-flex justify-content-center align-items-center">
        <div className="spinner-success spinner-border" role="status"></div>
      </div>
    );
  }

  return (
    <>
      {isEmpty(data.data.errors_history) ? (
        <div className="spiner-container d-flex justify-content-center align-items-center">
          <div className="card border-primary mb-3">
            <div className="error-list-item">
              <h2 className="card-dashboard">
                👋 Hello! You can access your dashboard after generating your
                essay!
              </h2>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="dashboard-container">
            <div className="dashboard-focus-area-list h-100 ">
              <div className="card border-primary mb-3 error-list ">
                <div className="card-body error-list-item">
                  <h5 className="card-title focus-area-title">
                    🔍 Please focus on this area:
                  </h5>
                  {!isEmpty(data?.data?.focus_area.focus_areas) &&
                  data?.data?.focus_area.focus_areas ? (
                    data?.data?.focus_area.focus_areas.map((item) => {
                      return (
                        <>
                          <h6 className="u-bold">{item?.name}</h6>
                          <p>{item?.description}</p>
                        </>
                      );
                    })
                  ) : (
                    <p style={{ color: "darkorange", "font-weight": "bold" }}>
                      Your feedback in process:{" "}
                      {data?.data?.focus_area.essay_feedback_status}...
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="dashboard-errors-list h-100">
              <div className="card border-primary mb-3 error-list ">
                <div className="card-body error-list-item">
                  <h5 className="card-title error-list-title">
                    📚 Error Feedback Resources:
                  </h5>
                  {
                    <div
                      dangerouslySetInnerHTML={{
                        __html: arrayToHtml(data?.data?.essay_errors),
                      }}
                    />
                  }
                </div>
              </div>
            </div>
            <div className="dashboard-last-action">
              <div className="card border-primary mb-3 h-100">
                <div className="card-body">
                  <h5 className="card-title">
                    The time that has passed since your last submitted essay:
                  </h5>
                  <h2 className="time-style">
                    🕒 {getTimeSinceLastAction(data?.data?.last_action)}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-chart">
            <div className="card border-primary h-100">
              <h5 className="card-title u-ml-20">Essay Errors History</h5>
              <ApexChart errorsHistory={data?.data?.errors_history} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;
