import {useTranslation} from 'react-i18next';
import {GoogleLogin} from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import './LoginForm.scss';

const LoginIntegrations = ({handleGoogleLogin, handleFacebookLogin, buttonWidth}) => {
  const {t} = useTranslation('common');
  const authButtonWidth = buttonWidth - 20

  return (
    <div className="d-flex flex-column justify-content-center">
      <p className="m-0.5 text-center">or</p>
      <div className="d-flex flex-column align-items-center gap-2">
        <GoogleLogin
          onSuccess={handleGoogleLogin}
          size="large"
          shape="rect"
          theme="white"
          locale="en_US"
          width={authButtonWidth}
          text="Sign in with Google"
        />
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          onSuccess={handleFacebookLogin}
          className="facebook-custom"
          style={{width: authButtonWidth}}
        >
          <div className="btn-contaner">
          <div className="logo btn-fb">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              version="1"
            >
              <path
                fill="#FFFFFF"
                d="M32 30a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v28z"
              />
              <path
                fill="#4267b2"
                d="M22 32V20h4l1-5h-5v-2c0-2 1.002-3 3-3h2V5h-4c-3.675 0-6 2.881-6 7v3h-4v5h4v12h5z"
              />
            </svg>
          </div>
          <p>Sign in with Facebook</p>
          </div>
        </FacebookLogin>
      </div>
    </div>
  );
};
export default LoginIntegrations;
