import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import "./Navigation.scss";

const Navigation = ({logo}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('menu-open', isOpen);
    document.documentElement.classList.toggle('menu-open', isOpen);
  }, [isOpen]);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Navbar className="bg-black" expand="lg">
      <Container className="content">
        <Navbar.Brand>
        
          <Nav.Link>
            <div className={`u-d-flex ${!logo && 'u-d-none  u-d-lg-block'} justify-content-center u-ml-10`}>
              <img
                src="images/logo_mobile.svg"
                alt="logo"
                width={183}
                height={88}
              />
            </div>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle
          style={{ filter: "brightness(100)" }}
          aria-controls="basic-navbar-nav"
          onClick={toggleNavbar}
        />
        <Navbar.Collapse
          style={{ justifyContent: "flex-end" }}
          id="basic-navbar-nav"
        >
          <Nav className="align-items-center">
            <NavLink
              className="text-white u-bold nav-link nav-link-landing"
              to="/"
            >
              Home
            </NavLink>
            <NavLink
              className="text-white u-bold nav-link nav-link-landing"
              to="/about"
            >
              About Us
            </NavLink>
            <NavLink
              className="text-white u-bold nav-link nav-link-landing"
              to="/pricing"
            >
              Pricing
            </NavLink>
            <NavLink
              className="text-white u-bold nav-link nav-link-landing"
              to="/login"
            >
              Log In
            </NavLink>
            <NavLink
              className="text-white u-bold nav-bordered nav-link  nav-link-landing"
              to="/signup"
            >
              Sign Up
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default Navigation;
