import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { ESSAY } from "../constants";
import EssayPlanResult from "./EssayPlanResult";
import DashboardLayout from "../layout/DashboardLayout";
import useGetEssayItem from "./useGetEssayItem";
import useMe from "../me/useMe";

const EssayPageResults = () => {
  let { id } = useParams();
  const essay = useGetEssayItem(id)?.data?.data;
  const { t } = useTranslation("common");
  const queryClient = useQueryClient();
  const username = useMe().data?.data?.username;

  function calculateProgress(task) {
    switch (task) {
      case "Looking for relevant examples":
        return 1;
      case "Started evaluation of individual sections":
        return 15;
      case "Checking essay plan":
        return 30;
      case "Analyzing key points of essay":
        return 50;
      case "Adding comment from Michael":
        return 75;
      case "Checking for errors":
        return 85;
      case "Preparing results for display":
        return 99;
      default:
        return 0;
    }
  }

  useEffect(() => {
    let intervalId;
    if (essay?.feedbacks && isEmpty(essay?.feedbacks)) {
      intervalId = setInterval(() => {
        queryClient.invalidateQueries([ESSAY, id]);
      }, 10000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [id, queryClient, essay?.feedbacks]);

  const generateMarkup = (text) => {
    let html = "";

    const generateSectionMarkup = (section, headingLevel) => {
      for (const key in section) {
        if (typeof section[key] === "object") {
          html += `<h${headingLevel}>${key}:</h${headingLevel}>\n`;
          generateSectionMarkup(section[key], Math.min(headingLevel + 1, 6));
        } else if (Array.isArray(section[key])) {
          html += "<p>\n";
          section[key].forEach((item) => {
            html += `  ${item}<br>\n`;
          });
          html += "</p>\n";
        } else if (typeof section[key] === "string") {
          if (key === "BiggestError") {
            html += `<p style="color: red; font-weight: bold;">${section[key]}</p>\n`;
          } else {
            html += `<p>${section[key]}</p>\n`;
          }
        }
      }
    };

    const topLevelHeading = 4;
    generateSectionMarkup(text, topLevelHeading);
    html += "</body>\n</html>";
    return html;
  };

  return (
    <DashboardLayout>
      {essay ? (
        <div>
          <div className="pagetitle">
            <h1>{t("result.titlePage")}</h1>
          </div>
          <section className="section">
            <div className="row">
              <div className="col-lg-11 col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row mb-3 mt-3">
                      <h5>{t("result.theme")}</h5>
                      <div className="col-sm-10">
                        <p>{essay.theme}</p>
                      </div>
                    </div>
                    <div className="row mb-3 mt-3">
                      <h5>{t("result.timeTaken")}</h5>
                      <div className="col-sm-10">
                        <p>{essay.time_taken}</p>
                      </div>
                    </div>
                    <div className="row mb-3 mt-3">
                      <h5>{t("result.taskType")}</h5>
                      <div className="col-sm-10">
                        <p>{essay.task_type}</p>
                      </div>
                    </div>
                    <div className="row mb-3 mt-3">
                      <h5>{t("result.prompt")}</h5>
                      <div className="col-sm-10">
                        <p>{essay.prompt}</p>
                      </div>
                    </div>
                    {essay.contention && (
                      <EssayPlanResult
                        contention={essay.contention}
                        topic_sentence_1={essay.topic_sentence_1}
                        topic_sentences_2={essay.topic_sentences_2}
                      />
                    )}
                    {!essay.sectioned ? (
                      <div className="row mb-3">
                        <h5>{t("result.essay")}</h5>
                        <div className="col-sm-10">
                          <p>{essay.body}</p>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="row mb-3">
                          <h5>{t("result.introduction")}</h5>
                          <div className="col-sm-10">
                            <p>{essay.intro}</p>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <h5>{t("result.essayBody")}</h5>
                          <div className="col-sm-10">
                            <p>{essay.body}</p>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <h5>{t("result.conclusion")}</h5>
                          <div className="col-sm-10">
                            <p>{essay.conclusion}</p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {essay.feedbacks && !isEmpty(essay.feedbacks) ? (
            <>
              <div className="pagetitle mt-3 pb-3">
                <h1>{t("result.feedback")}</h1>
                <p className="u-bold">
                  {t("result.wellDone", { me: username })}
                </p>
              </div>
              <p className="feedback-disclaimer">{t("result.feedbackDisclaimer")}</p>
              <section className="section">
                <div className="row">
                  <div className="col-lg-11 col-12">
                    {essay.feedbacks.map((item, index) => {
                      return (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: generateMarkup(item.text.feedback),
                          }}
                        />
                      );
                    })}
                  </div>
                </div>
              </section>
            </>
          ) : (
            <div className="d-flex align-items-start flex-column">
              <p className="fw-bold">
                {essay.feedback_status}{" "}
                {calculateProgress(essay.feedback_status)}%
              </p>
              <div className="progressVisualFull">
                <div
                  style={{
                    width: `${calculateProgress(essay.feedback_status)}%`,
                    backgroundColor: "#fd8387",
                  }}
                  className="progressVisualPart"
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="spinner-border"
          style={{ width: "3rem", height: "3rem" }}
          role="status"
        ></div>
      )}
    </DashboardLayout>
  );
};
export default EssayPageResults;
