import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import "../utilities.scss";
import "./Terms.scss";

const Terms = () => {
  return (
    <div className="bg-black">
      <Navigation logo={true} />
      <section className="sectionLanding">
        <div className="col-md-12 col-lg-8">
          <div className="u-mb-40 z-index-10">
            <h1 className="heading1 u-mt-16 u-bold u-text-white">
              Terms & Conditions
            </h1>
          </div>
          <ul className="List">
            <li className="List-itemNumber one">
              <h4 className="body4 u-bold mb-2 u-text-white">
                About the Website
              </h4>
              <p className="body4 u-text-white">
                (a) Welcome to LikeMike.ai (Website). The Website allows you to
                access and use the LikeMike™ Ai (Services).
              </p>
              <p className="body4 u-text-white">
                (b) The Website is operated by 90plusgamsat Pty Ltd, ACN
                647674686 . Access to and use of the Website, or any of its
                associated products or Services, is provided by 90plusgamsat Pty
                Ltd. Please read these terms and conditions (Terms) carefully.
                By using, browsing and/or reading the Website, this signifies
                that you have read, understood and agree to be bound by the
                Terms. If you do not agree with the Terms, you must cease usage
                of the Website, or any of Services, immediately.
              </p>
              <p className="body4 u-text-white">
                (c) 90plusgamsat Pty Ltd reserves the right to review and change
                any of the Terms by updating this page at its sole discretion.
                When 90plusgamsat Pty Ltd updates the Terms, it will use
                reasonable endeavours to provide you with notice of updates to
                the Terms. Any changes to the Terms take immediate effect from
                the date of their publication. Before you continue, we recommend
                you keep a copy of the Terms for your records.
              </p>
            </li>
            <li className="List-itemNumber two">
              <h4 className="body4 u-bold mb-2 u-text-white">
                Acceptance of the Terms
              </h4>
              <p className="body4 u-text-white">
                You accept the Terms by remaining on the Website. Where the
                option is available in the user interface, you may also accept
                the Terms by clicking to accept or agree to the Terms.
              </p>
            </li>
            <li className="List-itemNumber three">
              <h4 className="body4 u-bold mb-2 u-text-white">
                About the Service
              </h4>
              <p className="body4 u-text-white">
                (a) LikeMike™ Ai is a GAMSAT Section 2 essay marking platform..
              </p>
              <p className="body4 u-text-white">
                (b) Presale accounts are currently offered. You acknowledge and
                agree that the accounts offered, as well as the account features
                may change from time to time, and may be governed by separate
                terms which apply specific to the account. Where special
                account-specific terms apply, you will be informed, and must
                accept those terms before you are given such an account. For the
                avoidance of doubt these Terms apply unless otherwise agreed or
                amended by account-specific terms.
              </p>
              <p className="body4 u-text-white">
                (c) Some accounts may be governed by a separate Software
                Licensing Agreement with 90plusgamsat Pty Ltd, which may amend
                the terms of use. For the avoidance of doubt these Terms apply
                unless otherwise agreed or amended by the terms of an applicable
                Software Licensing Agreement.
              </p>
              <p className="body4 u-text-white">
                (d) Purchasing of a Presale account via presale entitled users
                to access to full functionality as the service advances and
                improves, in accordance with the terms set out in Clause 7.
              </p>
            </li>
            <li className="List-itemNumber four">
              <h4 className="body4 u-bold mb-2 u-text-white">
                Acceptable use of the Service
              </h4>
              <p className="body4 u-text-white">
                (a) LikeMike™ Ai, its related features, and website must only be
                used lawfully. 90plusgamsat Pty Ltd reserves the right to
                suspend, cancel, or otherwise deny access to users and accounts
                who use the service:
              </p>
              <p className="body4 u-text-white">
                (i) To engage in any act that would disrupt the access,
                availability, and security of LikeMike™ Ai and other
                90plusgamsat Pty Ltd services, including but not limited to:
              </p>
              <p className="body4 u-text-white">
                (A) Tampering with, reverse-engineering, or hacking our servers.
              </p>
              <p className="body4 u-text-white">
                (B) Modifying, disabling, or compromising the performance
                LikeMike™ Ai or other 90plusgamsat Pty Ltd services.
              </p>
              <p className="body4 u-text-white">
                (C) Compromising the integrity of our system, including probing,
                scanning and testing the vulnerability of our system unless
                expressly permitted by 90plusgamsat Pty Ltd.
              </p>
              <p className="body4 u-text-white">
                (D) Overwhelming, or attempting to overwhelm our infrastructure
                by imposing an unreasonably large load on our systems that
                consume extraordinary resources.
              </p>
              <p className="body4 u-text-white">
                (b) For any illegal purpose, or to violate any laws, including
                and without limitation to data, privacy, and export control
                laws.
              </p>
              <p className="body4 u-text-white">
                (c) To stalk, harass or threaten users and any member of the
                public.
              </p>
              <p className="body4 u-text-white">
                (d) To misrepresent or defraud any user or member of the public
                through phishing, spoofing, manipulating headers or other
                identifiers, impersonating anyone else, or falsely implying any
                sponsorship or association with 90plusgamsat Pty Ltd or any
                third party
              </p>
              <p className="body4 u-text-white">
                (e) To access or search any part of the Service, or any other
                Service owned by 90plusgamsat Pty Ltd other than our publicly
                supported interface, or otherwise allowed for in an applicable
                Software Licensing Agreement.
              </p>
              <p className="body4 u-text-white">
                (f) To post, upload, share, or otherwise circulate content in
                violation of LikeMike™ Ai’s content policy
              </p>
            </li>
            <li className="List-itemNumber five">
              <h4 className="body4 u-bold mb-2 u-text-white">
                Data Deletion Policy
              </h4>
              <p className="body4 u-text-white">
                You can request that your data be removed from our records by emailing
                Michael@90plusgamsat.com and we will respond within 48 hours with confirmation
                of our actioning your request.
              </p>
            </li>
            <li className="List-itemNumber six">
              <h4 className="body4 u-bold mb-2 u-text-white">
                Security and Data Privacy
              </h4>
              <p className="body4 u-text-white">
                90plusgamsat Pty Ltd takes your privacy seriously and
                information provided through your use of the Website and/or
                Services are subject to 90plusgamsat Pty Ltd’s Privacy Policy,
                which is available on the Website. The Privacy Policy also
                addresses 90plusgamsat Pty Ltd’s processes, policies, and
                obligations in respect of LikeMike™ Ai security breaches.
              </p>
            </li>
            <li className="List-itemNumber seven">
              <h4 className="body4 u-bold mb-2 u-text-white">Data Use</h4>
              <p className="body4 u-text-white">
                90plusgamsat Pty Ltd collects, stores, and processes your data
                on LikeMike™ Ai. The data is used to provide Services to you, as
                well as to facilitate 90plusgamsat Pty Ltd’s business
                operations. The Privacy Policy outlined how your data is
                collected, stored, and processed by 90plusgamsat Pty Ltd. The
                Privacy Policy also addresses 90plusgamsat Pty Ltd's processes,
                policies, and obligations in respect of data encryption and
                removal requests.
              </p>
            </li>

            <li className="List-itemNumber eight">
              <h4 className="body4 u-bold mb-2 u-text-white">
                Subscription to use the Service
              </h4>
              <p className="body4 u-text-white">
                (a) In order to access the Services, you must first purchase a
                subscription through the Website (Subscription) and pay the
                applicable fee for the selected Subscription (Subscription Fee).
                After purchasing a Subscription, you will be considered a member
                (‘Member’).
              </p>
              <p className="body4 u-text-white">
                (b) In purchasing the Subscription, you acknowledge and agree
                that it is your responsibility to ensure that the Subscription
                you elect to purchase is suitable for your use.
              </p>
              <p className="body4 u-text-white">
                (c) Before, during or after you have purchased the Subscription,
                you will then be required to register for an account through the
                Website before you can access the Services (Account).
              </p>
              <p className="body4 u-text-white">
                (d) As part of the registration process, or as part of your
                continued use of the Services, you may be required to provide
                personal information about yourself (such as identification or
                contact details), including but not limited to your email
                address and any prior GAMSAT scores (for the purpose of visually
                tracking your current progress to you against your prior
                results). This information will never be shared with a third
                party.
              </p>
              <p className="body4 u-text-white">
                (e) You warrant that any information you give to 90plusgamsat
                Pty Ltd in the course of completing the registration process
                will always be accurate, correct and up to date.
              </p>
              <p className="body4 u-text-white">
                (f) Once you have completed the registration process, you will
                be a registered user of the Website and agree to be bound by the
                Terms ("User"). As a Member you will be granted immediate access
                to the Services from the time you have completed the
                registration process until the subscription period expires
                (Subscription Period).
              </p>
              <p className="body4 u-text-white">
                (g) You may not use the Services and may not accept the Terms
                if:
              </p>
              <p className="body4 u-text-white">
                (i) you are not of legal age to form a binding contract with
                90plusgamsat Pty Ltd; or
              </p>
              <p className="body4 u-text-white">
                (ii) you are a person barred from receiving the Services under
                the laws of Australia or other countries including the country
                in which you are resident or from which you use the Services.
              </p>
            </li>
            <li className="List-itemNumber nine">
              <h4 className="body4 u-bold mb-2 u-text-white">Payments</h4>
              <p className="body4 u-text-white">
                (a) Subject to the terms of any applicable Software License
                Agreement, the Subscription Fee may be paid by all payment
                methods available on the Website, and may change from time to
                time.
              </p>
              <p className="body4 u-text-white">
                (b) Payments made in the course of your use of LikeMike™ Ai may
                be made using third-party applications and services not owned,
                operated, or otherwise controlled by 90plusgamsat Pty Ltd. You
                acknowledge and agree that 90plusgamsat Pty Ltd will not be
                liable for any losses or damage arising from the operations of
                third-party payment applications and services. You further
                acknowledge and warrant that you have read, understood and agree
                to be bound by the terms and conditions of the applicable
                third-party payment applications and services you choose to use
                as a payment method for LikeMike™ Ai services.
              </p>
              <p className="body4 u-text-white">
                (c) You acknowledge and agree that where a request for the
                payment of the Subscription Fee is returned or denied, for
                whatever reason, by your financial institution or is unpaid by
                you for any other reason, then you are liable for any costs,
                including banking fees and charges, associated with the
                Subscription Fee.
              </p>
              <p className="body4 u-text-white">
                (d) You agree and acknowledge that 90plusgamsat Pty Ltd can vary
                the Subscription Fee at any time and that the varied
                Subscription Fee will come into effect following the conclusion
                of the existing Subscription.
              </p>
            </li>

            <li className="List-itemNumber ten">
              <h4 className="body4 u-bold mb-2 u-text-white">Refund Policy</h4>
              <p className="body4 u-text-white">
                (a) 90plusgamsat Pty Ltd offers refunds in accordance with the
                Australian Consumer Law and on the terms set out in these terms
                and conditions. Any benefits set out in these terms and
                conditions may apply in addition to consumer's rights under the
                Australian Consumer Law.
              </p>
              <p className="body4 u-text-white">
                (b) 90plusgamsat Pty Ltd will provide a full refund within 30
                days, plus 15% for any reason (Refund).
              </p>
            </li>

            <li className="List-itemNumber eleven">
              <h4 className="body4 u-bold mb-2 u-text-white">
                Copyright and Intellectual Property
              </h4>
              <p className="body4 u-text-white">
                (a) The Website, the Services and all of the related products of
                90plusgamsat Pty Ltd are subject to copyright. The material on
                the Website is protected by copyright under the laws of
                Australia and through international treaties. Unless otherwise
                indicated, all rights (including copyright) in the Services and
                compilation of the Website (including but not limited to text,
                graphics, logos, button icons, video images, audio clips,
                Website code, scripts, design elements and interactive features)
                or the Services are owned or controlled for these purposes, and
                are reserved by 90plusgamsat Pty Ltd or its contributors.
              </p>
              <p className="body4 u-text-white">
                (b) All trademarks, service marks and trade names are owned,
                registered and/or licensed by 90plusgamsat Pty Ltd, who grants
                to you a worldwide, non-exclusive, royalty-free, revocable
                license whilst you are a User to:
              </p>

              <p className="body4 u-text-white">
                (i) use the Website pursuant to the Terms;
              </p>
              <p className="body4 u-text-white">
                (ii) copy and store the Website and the material contained in
                the Website in your device's cache memory; and
              </p>
              <p className="body4 u-text-white">
                (iii) print pages from the Website for your own personal and
                non-commercial use.
              </p>

              <p className="body4 u-text-white">
                (c) 90plusgamsat Pty Ltd does not grant you any other rights
                whatsoever in relation to the Website or the Services. All other
                rights are expressly reserved by 90plusgamsat Pty Ltd.
              </p>
              <p className="body4 u-text-white">
                (d) 90plusgamsat Pty Ltd retains all rights, title and interest
                in and to the Website and all related Services. Nothing you do
                on or in relation to the Website will transfer any:
              </p>

              <p className="body4 u-text-white">
                (i) business name, trading name, domain name, trade mark,
                industrial design, patent, registered design or copyright, or
              </p>
              <p className="body4 u-text-white">
                (ii) a right to use or exploit a business name, trading name,
                domain name, trade mark or industrial design, or
              </p>
              <p className="body4 u-text-white">
                (iii) a thing, system or process that is the subject of a
                patent, registered design or copyright (or an adaptation or
                modification of such a thing, system or process), to you.
              </p>

              <p className="body4 u-text-white">
                (e) You may not, without the prior written permission of
                90plusgamsat Pty Ltd and the permission of any other relevant
                rights owners: broadcast, republish, up-load to a third party,
                transmit, post, distribute, show or play in public, adapt or
                change in any way the Services or third party Services for any
                purpose, unless otherwise provided by these Terms. This
                prohibition does not extend to materials on the Website, which
                are freely available for re-use or are in the public domain.
              </p>
            </li>

            <li className="List-itemNumber twelve">
              <h4 className="body4 u-bold mb-2 u-text-white">
                General Disclaimer
              </h4>
              <p className="body4 u-text-white">
                (a) Nothing in the Terms limits or excludes any guarantees,
                warranties, representations or conditions implied or imposed by
                law, including the Australian Consumer Law (or any liability
                under them) which by law may not be limited or excluded.
              </p>
              <p className="body4 u-text-white">
                (b) Subject to this clause, and to the extent permitted by law:
              </p>

              <p className="body4 u-text-white">
                (i) all terms, guarantees, warranties, representations or
                conditions which are not expressly stated in the Terms are
                excluded; and
              </p>
              <p className="body4 u-text-white">
                (ii) 90plusgamsat Pty Ltd will not be liable for any special,
                indirect or consequential loss or damage (unless such loss or
                damage is reasonably foreseeable resulting from our failure to
                meet an applicable Consumer Guarantee), loss of profit or
                opportunity, or damage to goodwill arising out of or in
                connection with the Services or these Terms (including as a
                result of not being able to use the Services or the late supply
                of the Services), whether at common law, under contract, tort
                (including negligence), in equity, pursuant to statute or
                otherwise.
              </p>

              <p className="body4 u-text-white">
                (c) Use of the Website and the Services is at your own risk.
                Everything on the Website and the Services is provided to you
                "as is" and "as available" without warranty or condition of any
                kind. None of the affiliates, directors, officers, employees,
                agents, contributors and licensors of 90plusgamsat Pty Ltd make
                any express or implied representation or warranty about the
                Services or any products or Services (including the products or
                Services of 90plusgamsat Pty Ltd) referred to on the Website.
                This includes (but is not restricted to) loss or damage you
                might suffer as a result of any of the following:
              </p>
              <p className="body4 u-text-white">
                (i) failure of performance, error, omission, interruption,
                deletion, defect, failure to correct defects, delay in operation
                or transmission, computer virus or other harmful component, loss
                of data, communication line failure, unlawful third party
                conduct, or theft, destruction, alteration or unauthorised
                access to records;
              </p>
              <p className="body4 u-text-white">
                (ii) the accuracy, suitability or currency of any information on
                the Website, the Services, or any of its Services related
                products (including third party material and advertisements on
                the Website);
              </p>
              <p className="body4 u-text-white">
                (iii) costs incurred as a result of you using the Website, the
                Services or any of the products of 90plusgamsat Pty Ltd; and
              </p>
              <p className="body4 u-text-white">
                (iv) the Services or operation in respect to links which are
                provided for your convenience.
              </p>
            </li>

            <li className="List-itemNumber thirteen">
              <h4 className="body4 u-bold mb-2 u-text-white">
                Limitation of Liability
              </h4>
              <p className="body4 u-text-white">
                (a) 90plusgamsat Pty Ltd's total liability arising out of or in
                connection with the Services or these Terms, however arising,
                including under contract, tort (including negligence), in
                equity, under statute or otherwise, will not exceed the resupply
                of the Services to you.
              </p>
              <p className="body4 u-text-white">
                (b) You expressly understand and agree that 90plusgamsat Pty
                Ltd, its affiliates, employees, agents, contributors and
                licensors shall not be liable to you for any direct, indirect,
                incidental, special consequential or exemplary damages which may
                be incurred by you, however caused and under any theory of
                liability. This shall include, but is not limited to, any loss
                of profit (whether incurred directly or indirectly), any loss of
                goodwill or business reputation and any other intangible loss.
              </p>
            </li>
            <li className="List-itemNumber fourteen">
              <h4 className="body4 u-bold mb-2 u-text-white">
                Termination of Contract
              </h4>
              <p className="body4 u-text-white">
                (a) The Terms will continue to apply until terminated by either
                you or by 90plusgamsat Pty Ltd as set out below.
              </p>
              <p className="body4 u-text-white">
                (b) If you want to terminate the Terms, you may do so by:
              </p>

              <p className="body4 u-text-white">
                (i) not renewing the Subscription prior to the end of the
                Subscription Period;
              </p>
              <p className="body4 u-text-white">
                (ii) providing 90plusgamsat Pty Ltd with 1 days’ notice of your
                intention to terminate; and
              </p>
              <p className="body4 u-text-white">
                (iii) closing your accounts for all of the services which you
                use, where 90plusgamsat Pty Ltd has made this option available
                to you.
              </p>

              <p className="body4 u-text-white">
                (c) Any notices pursuant to Clause 13.2 above should be sent, in
                writing, to 90plusgamsat Pty Ltd via the 'Contact Us' link on
                our homepage.
              </p>
              <p className="body4 u-text-white">
                (d) 90plusgamsat Pty Ltd may at any time, terminate the Terms
                with you if:
              </p>

              <p className="body4 u-text-white">
                (i) you do not renew the Subscription at the end of the
                Subscription Period;
              </p>
              <p className="body4 u-text-white">
                (ii) you have breached any provision of the Terms or intend to
                breach any provision;
              </p>
              <p className="body4 u-text-white">
                (iii) 90plusgamsat Pty Ltd is required to do so by law;
              </p>
              <p className="body4 u-text-white">
                (iv) the provision of the Services to you by 90plusgamsat Pty
                Ltd is, in the opinion of 90plusgamsat Pty Ltd, no longer
                commercially viable;
              </p>
              <p className="body4 u-text-white">
                (v) Are found to be sharing your service with another User or
                corporate entity.
              </p>

              <p className="body4 u-text-white">
                (e) Subject to local applicable laws, 90plusgamsat Pty Ltd
                reserves the right to discontinue or cancel your Subscription or
                Account at any time and may suspend or deny, in its sole
                discretion, your access to all or any portion of the Website or
                the Services without notice if you breach any provision of the
                Terms or any applicable law or if your conduct impacts
                90plusgamsat Pty Ltd's name or reputation or violates the rights
                of those of another party.
              </p>
            </li>

            <li className="List-itemNumber fifteen">
              <h4 className="body4 u-bold mb-2 u-text-white">Indemnity</h4>
              <p className="body4 u-text-white">
                You agree to indemnify 90plusgamsat Pty Ltd, its affiliates,
                employees, agents, contributors, third party content providers
                and licensors from and against:
              </p>
              <p className="body4 u-text-white">
                (a) all actions, suits, claims, demands, liabilities, costs,
                expenses, loss and damage (including legal fees on a full
                indemnity basis) incurred, suffered or arising out of or in
                connection with your content;
              </p>
              <p className="body4 u-text-white">
                (b) any direct or indirect consequences of you accessing, using
                or transacting on the Website or attempts to do so; and/or
              </p>
              <p className="body4 u-text-white">(c) any breach of the Terms.</p>
            </li>
            <li className="List-itemNumber sixteen">
              <h4 className="body4 u-bold mb-2 u-text-white">Dispute Resolution</h4>
              <p className="body4 u-text-white">
                <span className="u-bold">15.1. Compulsory:</span> If a dispute arises out of or relates to the Terms,
                either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the
                following clauses have been complied with (except where urgent interlocutory relief is sought).
              </p>
              <p className="body4 u-text-white">
                <span className="u-bold">15.2. Notice:</span> A party to the Terms claiming a dispute (Dispute) has
                arisen under the Terms, must give written notice to the other party detailing the nature of the dispute,
                the desired outcome and the action required to settle the Dispute.
              </p>
              <p className="body4 u-text-white">
                <span className="u-bold">15.3. Resolution:</span> On receipt of that notice (Notice) by that other
                party, the parties to the Terms (Parties) must:
              </p>
              <p className="body4 u-text-white u-pl-4">
                (a) Within 28 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by
                negotiation or such other means upon which they may mutually agree;
              </p>
              <p className="body4 u-text-white">
                <span className="u-bold">15.4. Confidential:</span> All communications concerning negotiations made by
                the Parties arising out of and in connection with this dispute resolution clause are confidential and to
                the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable
                laws of evidence.
              </p>
              <p className="body4 u-text-white">
                <span className="u-bold">15.5. Termination of Mediation:</span> If 2 months have elapsed after the start
                of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator
                to terminate the mediation and the mediator must do so.
              </p>
            </li>

            <li className="List-itemNumber seventeen">
              <h4 className="body4 u-bold mb-2 u-text-white">Venue and Jurisdiction</h4>
              <p className="body4 u-text-white">
                The Services offered by 90plusgamsat Pty Ltd is intended to be viewed by residents of Australia. In the
                event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue
                for resolving any dispute shall be in the courts of Victoria.
              </p>
            </li>

            <li className="List-itemNumber eighteen">
              <h4 className="body4 u-bold mb-2 u-text-white">Governing Law</h4>
              <p className="body4 u-text-white">
                The Terms are governed by the laws of Victoria. Any dispute, controversy, proceeding or claim of
                whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall
                be governed, interpreted and construed by, under and pursuant to the laws of Victoria, Australia without
                reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing
                law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their
                successors and assigns.
              </p>
            </li>

            <li className="List-itemNumber nineteen">
              <h4 className="body4 u-bold mb-2 u-text-white">Severance</h4>
              <p className="body4 u-text-white">
                If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction,
                that part shall be severed and the rest of the Terms shall remain in force.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className="bg-black sectionLanding p-0">
        <Footer/>
      </section>
    </div>
  );
};
export default Terms;
