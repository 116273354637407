const PlayIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 10.0747C2 5.65643 5.58172 2.07471 10 2.07471C14.4183 2.07471 18 5.65643 18 10.0747C18 14.493 14.4183 18.0747 10 18.0747C5.58172 18.0747 2 14.493 2 10.0747ZM8 7.9078V12.2415C8 12.819 8.62522 13.1798 9.12521 12.8909L13.2501 10.5076C13.4048 10.4183 13.5 10.2532 13.5 10.0747C13.5 9.89609 13.4048 9.73108 13.2501 9.64174L9.12521 7.2584C8.62521 6.96951 8 7.33034 8 7.9078Z"
        fill="white"
      />
    </svg>
  );
};

export default PlayIcon;
