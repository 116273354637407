import { useTranslation } from "react-i18next";
import useGetProducts from "./useGetProducts";
import DashboardLayout from "../layout/DashboardLayout";
import "./Replenish.scss";
import ReplenishItem from "./ReplenishItem";

const Replenish = () => {
  const { t } = useTranslation("common");
  const products = useGetProducts()?.data?.data;

  return (
    <DashboardLayout>
      <div className="pt-4">
        <h5 className="text-center pb-0 fs-1 replenish-title">
          {t("replenish.title")}
        </h5>
      </div>
      <div className="pb-2">
        <p className="text-center pb-0 fs-3 replenish-description">
          {t("replenish.description")}
        </p>
      </div>
      <section className="section px-4">
        <div className="row">
          {products ? (
            products.map((item) => {
              return (
                <ReplenishItem
                  key={item.id}
                  credits={item.product_name}
                  slug={item.slug}
                  price={item.price}
                />
              );
            })
          ) : (
            <div
              className="spinner spinner-border"
              role="status"
            ></div>
          )}
        </div>
      </section>
    </DashboardLayout>
  );
};
export default Replenish;
