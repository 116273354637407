import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DashboardLayout from "../layout/DashboardLayout";
import EssayForm from "./EssayForm";
import Modal from 'react-bootstrap/Modal';
import useCreateEssay from "./useCreateEssay";
import "./Essay.scss";

const EssayPage = () => {
  const navigate = useNavigate();
  const [isDivided, setDivided] = useState(false);
  const [isTaskA, setTaskA] = useState(true);
  const useMutation = useCreateEssay();
  const { t } = useTranslation("common");
  const [showPopup, setShowPopup] = useState(false)

  const handleSubmit = (data) => {
    useMutation.mutate(
      {
        theme: data.theme,
        sectioned: data.sectioned,
        task_type: data.task_type,
        time_taken: parseInt(data.time_taken),
        body: data.body,
        prompt: data.prompt,
        ...(data.sectioned && {
          intro: data.intro,
          conclusion: data.conclusion,
        }),
        ...isTaskA && {
          contention: data.contention,
          topic_sentence_1: data.topic_sentence_1,
          topic_sentences_2: data.topic_sentences_2,
        },
      },
      {
        onSuccess: async (data) => {
          setShowPopup(true);
          setTimeout(() => {
            setShowPopup(false);
            localStorage.removeItem("form");
            localStorage.removeItem("new_essay");
            navigate(`/essays/results/${data.data.id}`);
          }, 4000); 
        },
      }
    );
  };

  return (
    <DashboardLayout>
      <div className="pagetitle">
        <h1>{t("essay.title")}</h1>
        <nav>
          <ol className="breadcrumb">
            <li dangerouslySetInnerHTML={{
                            __html: `${t("essay.description")}`}}></li>
          </ol>
        </nav>
      </div>
      <Modal
      show={showPopup}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation
    >
      <Modal.Body style={{"height":"200px"}} className="d-flex align-items-center justify-content-center flex-column">
        <h4 className="modal-text">{t("form.modalTitle")}</h4>
        <p className="fw-bold text-center">{t("form.modalDescription")}</p>
      </Modal.Body>
    </Modal>
      <section className="section">
        <div className="row">
          <div className="col-lg-11 col-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{t("essay.fillNextFields")}</h5>
                <EssayForm
                  onSubmit={handleSubmit}
                  isDivided={isDivided}
                  setDivided={setDivided}
                  setTaskA={setTaskA}
                  isTaskA={isTaskA}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </DashboardLayout>
  );
};
export default EssayPage;
