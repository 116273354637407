import { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import LogoutIcon from "../components/icons/LogoutIcon";
import EssayItem from "./EssayItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./AsideMenu.scss";
import ConfettiExplosion from 'react-confetti-explosion';

const AsideMenu = ({handleLogOut, essaysList}) => {
  const {t} = useTranslation("common");
  const navigate = useNavigate();
  const [isExploding, setIsExploding] = useState(false);
  const essay = localStorage.getItem('new_essay')

  useEffect(() => {
    if (isExploding) {
      const timeoutId = setTimeout(() => {
        navigate("/essays/create-essay");
      }, 800);

      return () => clearTimeout(timeoutId);
    }
  }, [isExploding, navigate]);

  const handleClick = async () => {
    localStorage.setItem('new_essay', true)
    setIsExploding(true)
  }

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <li className="nav-item">
          {essay === null && <Button
            className="mb-2 w-100 btn-red align-items-center justify-content-center"
            disabled={essay}
            onClick={handleClick}
          >
            <span>{t('button.addNewEssay')}</span>
          </Button>}
        </li>
        {isExploding && <ConfettiExplosion zIndex={1000} />}
        {essay && <EssayItem name={t('navigation.newEssay')} url={'/essays/create-essay'}/>}
        <li className="nav-heading py-2 mt-4">{t('navigation.previousEssays')}</li>
        {essaysList &&
          essaysList.map((item) => {
            return <EssayItem key={item.id} name={item.theme} url={`/essays/results/${item.id}`} />;
          })}
        <li className="nav-heading mt-auto py-2 d-flex justify-content-center w-100">
          <Button className="btn btn-light w-100 " onClick={handleLogOut}>
            <LogoutIcon />
            <span className="ms-2">{t('button.logOut')}</span>
          </Button>
        </li>
      </ul>
    </aside>
  );
};

export default AsideMenu;
