import { useTranslation } from "react-i18next";

const EssayPlanResult = ({
  contention,
  topic_sentence_1,
  topic_sentences_2,
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <div className="row mb-3">
        <h5>{t("result.contention")}</h5>
        <div className="col-sm-10">
          <p>{contention}</p>
        </div>
      </div>
      <div className="row mb-3">
        <h5>{t("result.topicSentences1")}</h5>
        <div className="col-sm-10">
          <p>{topic_sentence_1}</p>
        </div>
      </div>
      <div className="row mb-3">
        <h5>{t("result.topicSentences2")}</h5>
        <div className="col-sm-10">
          <p>{topic_sentences_2}</p>
        </div>
      </div>
    </>
  );
};
export default EssayPlanResult;
