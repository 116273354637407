import { useTranslation } from "react-i18next";
import WordCounter from "./WordCounter";
import "./Essay.scss";

const EssayFormSectionsBlock = ({register, errors, watch}) => {
  const {t} = useTranslation("common");

  return (
    <>
      <div className="row mb-3">
        <label htmlFor="intro" className="col-sm-2 col-form-label">
          {t('form.introduction')}
        </label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            {...register("intro", { required: true })}
            placeholder={t('form.placeholder.introduction')}
            style={{height: "100px"}}
          ></textarea>
          <div className="d-flex justify-content-between">
            <div className="mt-2">
              {errors?.intro?.type === "required" && (
                <p className="error">{t("form.error")}</p>
              )}
            </div>
            <WordCounter value={watch("intro")} />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="inputPassword" className="col-sm-2 col-form-label">
        {t('form.essay')}
        </label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            {...register("body", { required: true })}
            placeholder={t('form.placeholder.essayDivide')}
            style={{height: "300px"}}
          ></textarea>
          <div className="d-flex justify-content-between">
            <div className="mt-2">
              {errors?.body?.type === "required" && (
                <p className="error">{t("form.error")}</p>
              )}
            </div>
            <WordCounter value={watch("body")} />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label htmlFor="conclusion" className="col-sm-2 col-form-label">
           {t('form.conclusion')}
        </label>
        <div className="col-sm-10">
          <textarea
            className="form-control"
            {...register("conclusion", { required: true })}
            placeholder={t('form.placeholder.conclusion')}
            style={{height: "100px"}}
          ></textarea>
          <div className="d-flex justify-content-between">
            <div className="mt-2">
              {errors?.conclusion?.type === "required" && (
                <p className="error">{t("form.error")}</p>
              )}
            </div>
            <WordCounter value={watch("conclusion")} />
          </div>
        </div>
      </div>
    </>
  );
};
export default EssayFormSectionsBlock;
