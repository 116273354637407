export const ME = "me";
export const ESSAYS = "essays";
export const ESSAY = "essay";
export const TOKEN_KEY = "tokenKey";
export const PRODUCTS = "products";
export const DASHBOARD = "dashboard";
export const ORDER_RESULT = "order_result"
export const HttpStatus = {
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 403,
};
