import DashboardLayout from "../layout/DashboardLayout";
import { useTranslation } from "react-i18next";
import Dashboard from '../dashboard/Dashboard';
import "./Main.scss";

const Main = () => {
  const {t} = useTranslation("common");

  return (
    <DashboardLayout>
      <div className="pagetitle">
        <h1>{t('main.title')}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              {t('main.description')}
            </li>
          </ol>
        </nav>
        <Dashboard/>
      </div>
    </DashboardLayout>
  );
};

export default Main;
