const BurgerMenuIcon = () => {
  return (
    <svg
      style={{ height: 20, width: 20, fill: "black" }}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
    </svg>
  );
};

export default BurgerMenuIcon;
